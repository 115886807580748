/**
 * Represents a FilePageItem object used for managing individual pages of a file.
 *
 * @class FilePageItem
 */
export class FilePageItem {
    /**
     * The unique identifier for the file page item.
     * @type {string}
     */
    Id;

    /**
     * The unique identifier for the associated file.
     * @type {string}
     */
    FileId;

    /**
     * The base64-encoded image data representing the page image.
     * @type {string}
     */
    ImageData;

    /**
     * Additional data associated with the page.
     * @type {any}
     */
    PageData;

    /**
     * The name of the associated file.
     * @type {string}
     */
    FileName;

    /**
     * The page number of the file page.
     * @type {number}
     */
    PageNumber;

    /**
     * The total number of pages in the file.
     * @type {number}
     */
    PageCount;

    /**
     * The width of the page image.
     * @type {number}
     */
    Width;

    /**
     * The height of the page image.
     * @type {number}
     */
    Height;

    /**
     * The rotation angle of the page image (default: -1).
     * @type {number}
     */
    Rotation;

    /**
     * Indicates whether the page is marked as deleted (default: false).
     * @type {boolean}
     */
    Deleted;

    /**
     * The original page number before any changes.
     * @type {number}
     */
    OldPageNumber;

    /**
     * Creates a new FilePageItem object.
     *
     * @param {string} Id - The unique identifier for the file page item.
     * @param {string} FileId - The unique identifier for the associated file.
     * @param {string} ImageData - The base64-encoded image data representing the page image.
     * @param {any} PageData - Additional data associated with the page.
     * @param {string} FileName - The name of the associated file.
     * @param {number} PageNumber - The page number of the file page.
     * @param {number} PageCount - The total number of pages in the file.
     * @param {number} Width - The width of the page image.
     * @param {number} Height - The height of the page image.
     * @param {number} Rotation - The rotation angle of the page image (default: -1).
     * @param {boolean} Deleted - Indicates whether the page is marked as deleted (default: false).
     */
    constructor(
        Id: string,
        FileId: string,
        ImageData: string,
        PageData: any,
        FileName: string,
        PageNumber: number,
        PageCount: number,
        Width: number,
        Height: number,
        Rotation: number = -1,
        Deleted: boolean = false
    ) {
        this.Id = Id;
        this.FileId = FileId;
        this.PageData = PageData;
        this.ImageData = ImageData;
        this.PageNumber = PageNumber;
        this.FileName = FileName;
        this.Width = Width;
        this.Height = Height;
        this.PageCount = PageCount;
        this.Rotation = Rotation;
        this.Deleted = Deleted;
        this.OldPageNumber = this.PageNumber;
    }
}
