import { DocumentEditorContainerComponent } from '@syncfusion/ej2-react-documenteditor';
import { useEffect, useRef } from 'react';
import { fileOpenDocumentEditorService } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';

interface Props {
    filePath: string;
    fileName: string;
    fileProvider: string;
    refresh: any;
    fileSession: FileSession;
}

export default function FileManagerDocumentPreviewer({ fileName, filePath, fileProvider, refresh, fileSession }: Props) {
    const containerRef = useRef<DocumentEditorContainerComponent>(null);


    useEffect(() => {
        const resizeDocumentEditor = () => {
            if (containerRef && containerRef.current) {
                const containerElement = containerRef.current.element;
                const parentElement = containerElement.parentElement;
                if (parentElement) {
                    const width = parentElement.clientWidth;
                    const height = parentElement.clientHeight;
                    containerRef.current.resize(width, height);
                    containerRef.current.documentEditor.fitPage('FitPageWidth');
                }
            }
        };

        // Call the function to resize the document editor
        resizeDocumentEditor();

        // Add a resize event listener to the window to handle window resizing
        window.addEventListener('resize', resizeDocumentEditor);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', resizeDocumentEditor);
        };
    }, [refresh]);


    useEffect(() => {
        async function loadDocumentFromServer() {
            try {

                var fileExt = fileName.split('.').pop();
                if (fileExt?.toLowerCase() === "docx") {
                    const result = await fileOpenDocumentEditorService(filePath,fileName, fileProvider, fileSession);
                    if (result) {
                        // get the data from the result
                        const documentData = result?.data;
                        if (documentData) {
                            if (containerRef && containerRef.current && containerRef.current.documentEditor) {
                                //JSON.stringify(documentData)
                                containerRef.current.documentEditor.open(documentData);
                                containerRef.current.documentEditor.documentName = fileName;
                                containerRef.current.documentChange = () => {
                                    containerRef?.current?.documentEditor.focusIn();
                                };
                            }
                        }
                    }
                }

            } catch (e) {
                console.log("Document Previewer : " + e);
            }
            finally {

            }
        }

        loadDocumentFromServer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileName]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {
                <DocumentEditorContainerComponent
                    id="container_prev"
                    autoResizeOnVisibilityChange={true}
                    ref={containerRef}
                    style={{ display: 'block' }}
                    height={'100vh'}
                    enableToolbar={false}
                    showPropertiesPane={false}
                    locale="en-US"
                />
            }
        </div>
    )
}
