/**
 * Represents a SplitPdfFile object used for splitting PDF files.
 *
 * @class SplitPdfFile
 */
export class SplitPdfFile {
    /**
     * The name of the PDF file.
     * @type {string | undefined}
     */
    name;

    /**
     * The path of the PDF file.
     * @type {string | undefined}
     */
    path;

    /**
     * An array of page numbers to split the PDF file.
     * @type {string[] | undefined}
     */
    pages;

    /**
     * An array of names for the split PDF files.
     * @type {string[] | undefined}
     */
    names;

    /**
     * Creates a new SplitPdfFile object.
     *
     * @param {string | undefined} [name] - The name of the PDF file.
     * @param {string | undefined} [path] - The path of the PDF file.
     * @param {string[] | undefined} [pages] - An array of page numbers to split the PDF file.
     * @param {string[] | undefined} [names] - An array of names for the split PDF files.
     */
    constructor(
        name?: string,
        path?: string,
        pages?: string[],
        names?: string[]
    ) {
        this.name = name;
        this.path = path;
        this.pages = pages;
        this.names = names;
    }
}
