/**
 * Represents a list of menu items typically used in applications interfacing with Odoo or similar platforms.
 *
 * @class MenuList
 */
export class MenuList {
    /**
     * A list of menus, each with an ID, name, text, and URL.
     * @type {Array<{id: number, name: string, text: string, url: string}>}
     */
    menus: Array<{ id: number; name: string; text: string; url: string; separator?: boolean }>;

    /**
     * Creates a new MenuList object with an optional array of menu items.
     * Automatically copies the 'name' to 'text' for each menu item.
     *
     * @param {Array<{id: number, name: string, url: string}>} [menus=[]] - Initial list of menus.
     */
    constructor(menus: Array<{ id: number; name: string; url: string }> = []) {
        this.menus = menus.map(menu => ({
            ...menu,
            text: menu.name  
        }));
    }

    /**
     * Inserts a separator item at the specified index.
     *
     * @param {number} index - The index to insert the separator at.
     */
    insertSeparator(index: number) {
        this.menus.splice(index, 0, { id: 0, name: '', text: '', url: '', separator: true });
    }
}
