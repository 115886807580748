import { AjaxRequestFailureEventArgs, AjaxRequestInitiateEventArgs, Annotation, Inject, Magnification, Navigation, PdfViewerComponent, ThumbnailView } from '@syncfusion/ej2-react-pdfviewer';
import { useEffect, useRef, useState } from 'react';
import { FileSession } from '../../models/FileSession';

interface Props {
    pdfPath: string;
    pdfName: string;
    serviceUrl: string;
    refresh: any;
    reloadComponent?: number;
    selectedPage?: number;
    provider: string;
    fileSession: FileSession;
}

export default function FileManagerPDFPreviewer({ pdfPath, pdfName, serviceUrl, refresh, selectedPage, reloadComponent, provider, fileSession }: Props) {
    const pdfPreviewerRefs = useRef<PdfViewerComponent>(null);
    const [showFullError, setShowFullError] = useState<boolean>(false);


    useEffect(() => {
        performRefresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    useEffect(() => {
        setShowFullError(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pdfPath]);

    useEffect(() => {

        if (reloadComponent && reloadComponent > 0) {
            pdfPreviewerRefs?.current?.load(pdfPath, "");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadComponent]);


    const performRefresh = () => {
        if (pdfPreviewerRefs && pdfPreviewerRefs.current) {
            pdfPreviewerRefs.current.updateViewerContainer();
        }
    }

    useEffect(() => {

        var page = selectedPage ?? 0;
        if (page === 0) {
            performRefresh();
            pdfPreviewerRefs?.current?.navigation?.goToFirstPage();
        }
        else {
            pdfPreviewerRefs?.current?.navigation?.goToPage(page);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage]);

    const onDocumentLoad = () => {
        pdfPreviewerRefs?.current?.navigation?.goToPage(selectedPage ?? 0);
    };

    const onDocumentLoadFailed = () => {
        setShowFullError(true);
        //alert('An unexpected error occured fetching the file. Please try again');
    }

    //check if the file is a pdf
    const isPDF = (name: string) => {
        var fileExt = name?.split('.').pop();
        if (fileExt && fileExt?.toLowerCase() === "pdf") {
            return true;
        }
        return false;
    }

    const ajaxRequestInitiate = (args: AjaxRequestInitiateEventArgs) => {
        var viewer = (document.getElementById("fileManagerPDFPreviewer") as any).ej2_instances[0];
        if (viewer.serverActionSettings.load === "Load") {
            args.JsonData['provider'] = provider;
            viewer.setJsonData(args.JsonData);
        }
    }

    const onAjaxRequestFailed = (args: AjaxRequestFailureEventArgs) => {

    }


    return (
        <>{(pdfPath && !showFullError && isPDF(pdfName)) ?
            <PdfViewerComponent
                ref={pdfPreviewerRefs}
                name={''}
                id="fileManagerPDFPreviewer"
                ajaxRequestFailed={onAjaxRequestFailed}
                documentPath={pdfPath}
                documentLoad={onDocumentLoad}
                documentLoadFailed={onDocumentLoadFailed}
                serviceUrl={serviceUrl}
                ajaxRequestInitiate={ajaxRequestInitiate}
                ajaxRequestSettings={{
                    ajaxHeaders: [
                        {
                            headerName: 'session-id',
                            headerValue: fileSession.sessionId?.toString() ?? "",
                        },
                        {
                            headerName: 'session-host',
                            headerValue: fileSession.sessionHost?.toString() ?? "",
                        },
                        {
                            headerName: 'session-uid',
                            headerValue: fileSession.sessionUid?.toString() ?? "",
                        },
                        {
                            headerName: 'session-b2c',
                            headerValue: fileSession.sessionB2C?.toString() ?? "",
                        }
                    ],
                    withCredentials: false,
                }}
                style={{
                    'height': '85vh',
                    'width': '98%',
                    'padding': '20px',
                    'aspectRatio': '1/1'
                }}

                enableMagnification={true}
            >
                <Inject services={[ThumbnailView, Magnification, Navigation, Annotation]} />
            </PdfViewerComponent> :
            <div>
                An unexpected error occured fetching the file. Please refresh try again
            </div>
        }
        </>
    )
}
