import { ToastUtility } from '@syncfusion/ej2-notifications';
import { ClickEventArgs } from '@syncfusion/ej2-react-buttons';
import { CustomToolbarItemModel, DocumentEditorContainerComponent, FormatType, Toolbar } from '@syncfusion/ej2-react-documenteditor';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { fileOpenDocumentEditorService, saveEditedDocument } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';
import NavBar from '../NavBar';

DocumentEditorContainerComponent.Inject(Toolbar);

interface Props {
    fileSession: FileSession;
}
function CharacterFormatView({ fileSession }: Props) {
    const containerRef = useRef<DocumentEditorContainerComponent>(null);

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const filePath = queryParams.get('path') ?? "";
    const fileName = queryParams.get('name') ?? "";
    const fileProvider = queryParams.get('provider') ?? "";

    let printToolItem: CustomToolbarItemModel = {
        prefixIcon: "e-print",
        tooltipText: "Print",
        text: "Print",
        id: "toolBarPrint"
    };
    let toolbarItems = [
        printToolItem, 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Bookmark', 'TableOfContents', 'Separator', 'Header', 'Footer', 'PageSetup', 'PageNumber', 'Break', 'InsertFootnote', 'InsertEndnote', 'Separator', 'Find', 'Separator', 'Comments', 'TrackChanges', 'Separator', 'LocalClipboard', 'RestrictEditing', 'Separator', 'FormFields', 'UpdateFields'] as any;


    useEffect(() => {
        async function loadDocumentFromServer() {
            try {
                showToast("Please wait...", "Loading Document", "e-toast-info");
                const result = await fileOpenDocumentEditorService(filePath, fileName,fileProvider, fileSession);
                if (result) {
                    // get the data from the result
                    const documentData = result?.data;
                    if (documentData) {
                        if (containerRef && containerRef.current && containerRef.current.documentEditor) {
                            //JSON.stringify(documentData)
                            containerRef.current.documentEditor.open(documentData);
                            containerRef.current.documentEditor.documentName = fileName;
                            containerRef.current.documentChange = () => {
                                containerRef?.current?.documentEditor.focusIn();
                            };
                        }
                    }
                }
            } catch (e) {
                showToast("An error occurred while fetching the document.", "Edit Document", "e-toast-danger")
                console.log(e);
            }
            finally {

            }

        }

        loadDocumentFromServer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 4000,
            cssClass: cssClass,

        });
    }


    const toolbarClick = (args: ClickEventArgs) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        var itemId = (args as any).item.id;

        if (itemId) {
            switch (itemId) {
                case 'toolBarSave':
                    showToast("Please wait...", "Saving Changes", "e-toast-info");
                    SaveFunction();


                    break;
                case 'toolBarPrint':
                    //Disable image toolbar item.
                    if (containerRef && containerRef.current) {
                        const container = containerRef.current;
                        const documentEditor = container.documentEditor;
                        documentEditor.print();
                    }
                    break;
                default:
                    break;
            }
        }
    }


    function SaveFunction() {
        if (containerRef && containerRef.current) {
            const container = containerRef.current;
            const documentEditor = container.documentEditor;
            var fileExt = fileName?.split('.').pop();
            var saveAsType = fileExt === ".txt" ? "Txt" : "Docx";
            documentEditor.saveAsBlob(saveAsType as FormatType).then((blob: Blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    const base64data = reader.result;
                    console.log("data is " + base64data);
                    if (base64data && typeof base64data === 'string') {

                        saveEditedDocument(base64data, filePath, fileProvider, fileSession)
                            .then(response => {
                                // handle success
                                showToast("Your changes have been saved", "Document Saved", "e-toast-success");
                            })
                            .catch(error => {
                                // handle error
                                showToast("An error occurred while saving the document", "Saving Changes", "e-toast-error");
                            });
                    }
                };
            });
        }
    }

    return (
        <div className="control-pane">
            <NavBar filePath={filePath}
                fileName={fileName}
                saveFunction={() => { SaveFunction() }}
                saveAsFunction={() => { }}
                cancelFunction={() => { window.close() }}
                closeFunction={() => { window.close() }} />
            <div className="control-section">

                <div id="documenteditor_container_body">
                    <DocumentEditorContainerComponent
                        id="container"
                        toolbarClick={(e) => toolbarClick(e)}
                        ref={containerRef}
                        toolbarItems={toolbarItems}
                        style={{ display: 'block' }}
                        height={'100vh'}
                        enableToolbar={true}
                        locale="en-US"
                    />
                </div>
            </div>
        </div>
    );
}

export default CharacterFormatView;
