import { combineReducers } from "@reduxjs/toolkit";
import { FileDetailsObject } from "../../models/FileDetails";
import { Action, ActionTypes } from "../actions";
export interface FileState {
    selectedFile: FileDetailsObject | null;
}

const initialState: FileState = {
    selectedFile: null,
};

export const fileReducer = (state = initialState, action: Action): FileState => {
    switch (action.type) {
        case ActionTypes.SELECT_FILE:
            return {
                ...state,
                selectedFile: action.payload,
            };
        default:
            return state;
    }
};


export default combineReducers({ fileReducer });
