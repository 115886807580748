/**
 * Represents a RemoveAttachedConnectionsRequest object used for fetching file connections.
 *
 * @class RemoveAttachedConnectionsRequest
 */
export class RemoveAttachedConnectionsRequest {

	/**
	 * The Target id of the odoo object.
	 * @type {string | undefined}
	 */
	TargetId;
	/**
	 * The Source id of the odoo object.
	 * @type {string | undefined}
	 */
	SourceId;

	/**
	 * Creates a new RemoveAttachedConnectionsRequest object.
	 *
	 * @param {string | undefined} [TargetId] - The target id.
	 * @param {string | undefined} [SourceId] - The source id.
	 * 
	 */

	constructor(
		TargetId?: string,
		SourceId?: string,
	) {
		this.TargetId = TargetId;
		this.SourceId = SourceId;
	}
}
