import { FileSession } from "../models/FileSession";
/**
 * Retrieves API headers from a given FileSession object.
 *
 * @param {FileSession} fileSession - The FileSession object containing session information.
 * @returns {Object} - An object containing API headers.
 * @property {string} 'session-id' - The session ID.
 * @property {string} 'session-host' - The session host.
 * @property {string} 'session-uid' - The session UID.
 * @property {string} 'session-b2c' - The B2C session information.
 */
export function getApiHeaders(fileSession: FileSession) {
    const sessionId = fileSession.sessionId || '';
    const sessionHost = fileSession.sessionHost || '';
    const sessionUid = fileSession.sessionUid || '';
    const sessionB2C = fileSession.sessionB2C || '';
    const headers = {
        'session-id': sessionId,
        'session-host': sessionHost,
        'session-uid': sessionUid,
        'session-b2c': sessionB2C,
    };

    return headers;
}