import { useMsal } from '@azure/msal-react';
import { Box } from '@mui/material';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { AppBarComponent } from '@syncfusion/ej2-react-navigations';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useEffect, useState } from 'react';
import { fetchMenuService, fetchOdooObjectMenuService, fetchOdooProfileMenuService, fetchOdooSettingsMenuService } from '../apis/fileService';
import { loginRequest } from '../authConfig';
import { FileSession } from '../models/FileSession';
import { MenuList } from '../models/MenuList';
import "./NavigationBar.scss";

interface Props {
    fileSession: FileSession;
}

export const NavigationBar = ({ fileSession }: Props) => {
    const { instance } = useMsal();
    let activeAccount;

    if (instance) {
        activeAccount = instance.getActiveAccount();
    }


    const [menuList, setMenuList] = useState<{ id: number; name: string; text: string; url: string; }[]>([]);
    const [menuSettingsList, setMenuSettingsList] = useState<{ id: number; name: string; text: string; url: string; }[]>([]);
    const [menuObjectList, setMenuObjectList] = useState<{ id: number; name: string; text: string; url: string; }[]>([]);
    const [menuProfileList, setMenuProfileList] = useState<{ id: number; name: string; text: string; url: string; }[]>([]);

    useEffect(() => {
        async function fetchSelectedFileContent() {
            try {
                if (fileSession) {
                    const result = await fetchMenuService(fileSession);
                    if (result.data) {
                        if (result.data.Success) {
                            const fileMenus = result.data.Data;
                            const menuListInstance = new MenuList(fileMenus);
                            const sortedMenus = menuListInstance.menus.sort((a, b) => {
                                const nameA = a.name.toUpperCase();
                                const nameB = b.name.toUpperCase();
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }
                                return 0; 
                            });

                            setMenuList(sortedMenus);
                        } else {
                            //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                        }
                    }

                    const resultSesttings = await fetchOdooSettingsMenuService(fileSession);
                    if (resultSesttings.data) {
                        if (resultSesttings.data.Success) {
                            const fileMenus = resultSesttings.data.Data;
                            const menuListInstance = new MenuList(fileMenus);

                            const sortedSettingsMenus = menuListInstance.menus.sort((a, b) => {
                                const nameA = a.name.toUpperCase();
                                const nameB = b.name.toUpperCase();
                                if (nameA < nameB) {
                                    return -1;
                                }
                                if (nameA > nameB) {
                                    return 1;
                                }
                                return 0;
                            });

                            setMenuSettingsList(sortedSettingsMenus);
                        } else {
                            //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                        }
                    }

                    const resultObject = await fetchOdooObjectMenuService(fileSession);
                    if (resultObject.data) {
                        if (resultObject.data.Success) {
                            const fileMenus = resultObject.data.Data;
                            const menuListInstance = new MenuList(fileMenus);

                            // Now you can set your menu list state with the menus from the instance
                            setMenuObjectList(menuListInstance.menus);
                        } else {
                            //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                        }
                    }


                    const resultProfileObject = await fetchOdooProfileMenuService(fileSession);
                    if (resultProfileObject.data) {
                        if (resultProfileObject.data.Success) {
                            const fileMenus = resultProfileObject.data.Data;
                            const menuListInstance = new MenuList(fileMenus);


                            // Insert separator after the second item (for example)
                            menuListInstance.insertSeparator(2);

                            setMenuProfileList(menuListInstance.menus);
                        } else {
                            //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                        }
                    }
                    //setMenuProfileList
                }
            } catch (e) {
            }
        }

        fetchSelectedFileContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileSession]);

    useEffect(() => {
        async function fetchSelectedFileContent() {
            try {
                if (fileSession) {
                    const resultSesttings = await fetchOdooSettingsMenuService(fileSession);
                    if (resultSesttings.data) {
                        if (resultSesttings.data.Success) {
                            const fileMenus = resultSesttings.data.Data;
                            const menuListInstance = new MenuList(fileMenus);

                            // Now you can set your menu list state with the menus from the instance
                            setMenuSettingsList(menuListInstance.menus);
                        } else {
                            //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                        }
                    }
                }
            } catch (e) {
            }
        }

        fetchSelectedFileContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileSession]);


    useEffect(() => {
        async function fetchSelectedFileContent() {
            try {
                if (fileSession) {


                    const resultObject = await fetchOdooObjectMenuService(fileSession);
                    if (resultObject.data) {
                        if (resultObject.data.Success) {
                            const fileMenus = resultObject.data.Data;
                            const menuListInstance = new MenuList(fileMenus);

                            // Now you can set your menu list state with the menus from the instance
                            setMenuObjectList(menuListInstance.menus);
                        } else {
                            //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                        }
                    }
                }
            } catch (e) {
            }
        }

        fetchSelectedFileContent();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileSession]);


    //export const fetchOdooObjectMenuService = (fileSession: FileSession) => {

    //    const headers = getApiHeaders(fileSession);

    //    return axios.post(`${SERVER_URL}api/FileProvider/FetchOdooObjectMenus`, null, { headers });
    //};

    //export const fetchOdooSettingsMenuService = (fileSession: FileSession) => {

    function onSelect(args: any) {
        window.location.href = args.item.url;
    }

    const handleLoginPopup = () => {
        instance
            .loginPopup({
                ...loginRequest,
                redirectUri: '/',
            })
            .then((response) => {
                // Access token is available in the response
                const accessToken = response.accessToken;

                console.log('Access Token:', accessToken);
            })
            .catch((error) => console.log(error));
    };

    const handleLoginRedirect = () => {
        if (window.location === window.parent.location) {
            instance.loginRedirect(loginRequest).catch((error) => console.log(error));
            // The page is in an iframe


        } else {
            handleLoginPopup();
            // The page is not in an iframe

        }
    };

    const handleLogoutRedirect = () => {
        if (window.location === window.parent.location) {
            instance.logoutRedirect();

        } else {
            handleLogoutPopup();
        }
    };

    const handleLogoutPopup = () => {
        instance.logoutPopup({
            mainWindowRedirectUri: '/', // redirects the top level app after logout
        });
    };

    const MenuIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14">
            <g fill="white">
                <rect width="4" height="4" x="0" y="0"></rect>
                <rect width="4" height="4" x="5" y="0"></rect>
                <rect width="4" height="4" x="10" y="0"></rect>
                <rect width="4" height="4" x="0" y="5"></rect>
                <rect width="4" height="4" x="5" y="5"></rect>
                <rect width="4" height="4" x="10" y="5"></rect>
                <rect width="4" height="4" x="0" y="10"></rect>
                <rect width="4" height="4" x="5" y="10"></rect>
                <rect width="4" height="4" x="10" y="10"></rect>
            </g>
        </svg>
    );

    const HoverIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 14 14">
            <g fill="red">
                <rect width="4" height="4" x="0" y="0"></rect>
                <rect width="4" height="4" x="5" y="0"></rect>
                <rect width="4" height="4" x="10" y="0"></rect>
                <rect width="4" height="4" x="0" y="5"></rect>
                <rect width="4" height="4" x="5" y="5"></rect>
                <rect width="4" height="4" x="10" y="5"></rect>
                <rect width="4" height="4" x="0" y="10"></rect>
                <rect width="4" height="4" x="5" y="10"></rect>
                <rect width="4" height="4" x="10" y="10"></rect>
            </g>
        </svg>
    );


    const MenuButton = () => {
        const [hover, setHover] = useState(false);

        return (
            <ButtonComponent
                aria-label='menu'
                style={{ backgroundColor: 'transparent', border: 'none', color: 'white' }}
            >
                {hover ? <HoverIcon /> : <MenuIcon />}
            </ButtonComponent>
        );
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <AppBarComponent mode="Dense" style={{ backgroundColor: '#694b61', color: 'white' }} >
                        <DropDownButtonComponent
                            items={menuList as any}
                            cssClass="e-caret-hide"
                            select={onSelect}
                            style={{ color: 'white', backgroundColor: 'transparent', border: 'none' }}
                        >
                            <MenuIcon />
                        </DropDownButtonComponent>
                        <div style={{ fontSize: '17px', color: 'white', marginLeft: '5px' }}>
                            File Manager
                        </div>
                        <Box>
                            <div className="unauth-button">
                                <DropDownButtonComponent
                                    items={menuObjectList as any}
                                    cssClass='e-caret-hide'
                                    select={onSelect}
                                    style={{ color: 'white', backgroundColor: 'transparent', border: 'none' }}
                                >Objects</DropDownButtonComponent>
                            </div>
                        </Box>
                        <Box>
                            <div className="unauth-button">
                                <DropDownButtonComponent
                                    items={menuSettingsList as any}
                                    cssClass='e-caret-hide'
                                    select={onSelect}
                                    style={{ color: 'white !important', backgroundColor: 'transparent', border: 'none' }}
                                >Configuration</DropDownButtonComponent>
                            </div>
                        </Box>
                        <div className="e-appbar-spacer"></div>
                        <Box>
                            <div className="unauth-button">
                                <DropDownButtonComponent
                                    items={menuList as any}
                                    iconCss='e-icons e-odoo-menu'
                                    cssClass='e-caret-hide'
                                    select={onSelect}
                                    style={{ color: 'white', backgroundColor: 'transparent', border: 'none' }}
                                ></DropDownButtonComponent>
                            </div>
                        </Box>
                        <Box>
                            <div className="unauth-button" style={{maxWidth:'200px', whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'} }>
                                <DropDownButtonComponent
                                    items={menuProfileList as any}
                                    cssClass='e-caret-hide'
                                    select={onSelect}
                                    style={{ color: 'white !important', backgroundColor: 'transparent', border: 'none' }}
                                >{fileSession.sessionB2C}</DropDownButtonComponent>
                            </div>
                        </Box>
                    </AppBarComponent>
                </div>
            </div>
        </>
    );
};
