/**
 * Represents a SaveFileRequest object used for saving files.
 *
 * @class SaveFileRequest
 */
export class SaveFileRequest {
    /**
     * The name of the file.
     * @type {string | undefined}
     */
    name;

    /**
     * The base64-encoded string representing the file content.
     * @type {string | undefined}
     */
    base64String;

    /**
     * The unique identifier for the file.
     * @type {string | undefined}
     */
    id;

    /**
     * The provider identifier associated with the file.
     * @type {string | undefined}
     */
    providerId;

    /**
     * Creates a new SaveFileRequest object.
     *
     * @param {string | undefined} [name] - The name of the file.
     * @param {string | undefined} [base64String] - The base64-encoded string representing the file content.
     * @param {string | undefined} [id] - The unique identifier for the file.
     * @param {string | undefined} [providerId] - The provider identifier associated with the file.
     */
    constructor(
        name?: string,
        base64String?: string,
        id?: string,
        providerId?: string
    ) {
        this.name = name;
        this.base64String = base64String;
        this.id = id;
        this.providerId = providerId;
    }
}
