import axios, { AxiosResponse } from "axios";
import { FileItem } from "../models/FileItem";
import { SaveFileRequest } from "../models/SaveFileRequest";
import { SplitPdfFile } from "../models/SplitPdfFile";
import { SERVER_URL } from "../environment/env_dev";
import { getApiHeaders } from "./apiHeaders";
import { FileSession } from "../models/FileSession";
import { FileClassRequest, LookupDataRequest } from "../models/FileClassProperty";
import { FileProviderConnection } from "../models/FileProviderConnection";
import { FileAttachmentRequest } from "../models/FileAttachmentRequest";
import { RemoveAttachedConnectionsRequest } from "../models/RemoveAttachedConnectionsRequest";
import { ConnectedSourceItemsRequest } from "../models/connectedSourceItemsRequest";



export const fileOpenService = (fileId: string, name: string, provider: string, fileSession: FileSession) => {
    fileId = encodeURIComponent(fileId);

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderGetBase64String?fileId=${fileId}&name=${name}&providerId=${provider}`, null, { headers });
};

export const fileGetConnectionsService = (fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderGetConnections`, null, { headers });
};


export const fileAddAttachmentService = (fileAttachmentRequest: FileAttachmentRequest[], fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/ItemConnections/AddConnections`, fileAttachmentRequest, { headers });
};

export const fileAttachedConnectionsService = (connectedSourceItemsRequest: ConnectedSourceItemsRequest, fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/ItemConnections/ConnectedSourceItems`, connectedSourceItemsRequest, { headers });
};

export const fileRemoveSelectedAttachedService = (removeAttachedConnectionsRequest: RemoveAttachedConnectionsRequest[], fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/ItemConnections/RemoveAttachedConnections`, removeAttachedConnectionsRequest, { headers });
};

export const fileCreateConnectionsService = (fileProviderConnection: FileProviderConnection, fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderCreateConnection`, fileProviderConnection, { headers });
};

export const filePropertiesService = (path: string, name: string, provider: string, fileSession: FileSession) => {
    path = encodeURIComponent(path);

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderGetJsonProperties?path=${path}&name=${name}&providerId=${provider}`, null, { headers });
};


export const filePropertyService = (fileId: string, fileSession: FileSession) => {
    fileId = encodeURIComponent(fileId);

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderGetFileProperties?fileId=${fileId}`, null, { headers });
};

export const fetchClassPropertiesService = (fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderFetchDocumentClasses`, null, { headers });
};


export const fetchLookupData = (lookupDataRequest: LookupDataRequest, fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    //return axios.post(`${SERVER_URL}api/FileProvider/ProviderFetchLookupData?fileId=${fileId}`, null, { headers });
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderFetchLookupData`, lookupDataRequest, { headers });
};


export const fetchMenuService = (fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderFetchMenus`, null, { headers });
};

export const fetchOdooObjectMenuService = (fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/FetchOdooObjectMenus`, null, { headers });
};

export const fetchOdooProfileMenuService = (fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/FetchOdooProfileMenus`, null, { headers });
};

export const fetchOdooSettingsMenuService = (fileSession: FileSession) => {

    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/FetchOdooSettingsMenus`, null, { headers });
};


export const saveClassPropertiesService = (fileClassRequest: FileClassRequest, fileId: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);

    return axios.post(`${SERVER_URL}api/FileProvider/ProviderSaveFileClasses?id=${fileId}`, fileClassRequest, { headers });
};


export const fileSaveService = (request: SaveFileRequest, fileSession: FileSession) => {
    //if (request.path) {
    //    request.path = encodeURIComponent(request.path);
    //}

    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderSaveBase64String`, request, { headers });
};

export const fileMergePDFService = (files: FileItem[], name: string, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderMergePDFFilesAsync?name=${name}&providerId=${provider}`, files, { headers });
};

export const fileSplitPDFService = (request: SplitPdfFile, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderSplitPDFFileAsync?providerId=${provider}`, request, { headers });
};

export const fileGetPDFThumbnailsAsync = (path: any, name: any, quality: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    path = encodeURIComponent(path);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFThumbnailsAsync?path=${path}&name=${name}&quality=${quality}&providerId=${provider}`, null, { headers });
};

export const fileGetPDFVirtualizedThumbnailsAsync = (path: any, name: any, startIndex: number, pageSize: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    path = encodeURIComponent(path);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderGetVirtualizedPDFThumbnailsAsync?path=${path}&name=${name}&currentPage=${startIndex}&size=${pageSize}&providerId=${provider}`, null, { headers });
};

export const fileGetPDFPageThumbnailsAsync = (path: any, name: any, quality: number, pageNumber: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    path = encodeURIComponent(path);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFPageThumbnailAsync?path=${path}&name=${name}&quality=${quality}&pageNumber=${pageNumber}&providerId=${provider}`, null, { headers });
};


export const filePDFMovePageUpAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFMovePageUpAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const filePDFMovePageDownAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFMovePageDownAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const filePDFRotateRightAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFRotateRightAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const filePDFRotateLeftAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFRotateLeftAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const filePDFRotateUpAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFRotateUpAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const filePDFRotateDownAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFRotateDownAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const filePDFRemovePageAsync = (documentPath: string, documentName: string, fileId: string, documentIndex: number, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderPDFRemovePageAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&documentIndex=${documentIndex}&providerId=${provider}`, null, { headers });
};

export const fileResetTempData = (fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderResetTestData`, null, { headers });
};

//Custom File Operations

export const fileRenameService = (path: string, name: string, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    path = encodeURIComponent(path);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderRenameItem?fullPath=${path}&newName=${name}&providerId=${provider}`, null, { headers });
};


export const fileCreateFolderService = (path: string, name: string, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    path = encodeURIComponent(path);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderCreateFolderItem?targetPath=${path}&fileName=${name}&providerId=${provider}`, null, { headers });
};


export const fileRemoveService = (path: string, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    path = encodeURIComponent(path);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderDeleteItem?path=${path}&providerId=${provider}`, null, { headers });
};


export const fileOpenDocumentEditorService = (fileId: string, fileName: string, provider: string, fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    fileId = encodeURIComponent(fileId);
    return axios.post(`${SERVER_URL}api/DocumentEditor/LoadDocument?fileId=${fileId}&name=${fileName}&providerId=${provider}`, null, { headers });
};



export const saveEditedDocument = (base64data: string, path: string, provider: string, fileSession: FileSession): Promise<AxiosResponse> => {
    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/DocumentEditor/SaveEditedDocument`, { data: base64data, path, providerId: provider }, { headers });
}

export const saveEditedPDFDocument = (base64data: string, path: string, provider: string, fileSession: FileSession): Promise<AxiosResponse> => {
    const headers = getApiHeaders(fileSession);
    return axios.post(`${SERVER_URL}api/FileProvider/EditPdfAsync`, { data: base64data, path, providerId: provider }, { headers });
}

export const filePDFEditPdfAsync = (documentPath: string, documentName: string, fileId: string, provider: string, commands: any[], fileSession: FileSession) => {
    const headers = getApiHeaders(fileSession);
    documentPath = encodeURIComponent(documentPath);
    return axios.post(`${SERVER_URL}api/FileProvider/ProviderEditPdfAsync?documentPath=${documentPath}&documentName=${documentName}& fileId=${fileId}&providerId=${provider}`, commands, { headers });
};
