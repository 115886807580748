import { ToastUtility } from '@syncfusion/ej2-notifications';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { ComboBoxComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { useEffect, useState } from 'react';
import { fetchClassPropertiesService, fetchLookupData, filePropertyService, saveClassPropertiesService } from '../../apis/fileService';
import { FileClassProperty, FileClassRequest, LookupDataRequest } from '../../models/FileClassProperty';
import { FileDetailsObject } from '../../models/FileDetails';
import { FolderItemClassResponse } from '../../models/FileItemClasses';
import { FileSession } from '../../models/FileSession';
import "../FileManager/FileProperties.scss";

interface Props {
    FileDetailsObject: FileDetailsObject;
    FolderItemClassResponse?: FolderItemClassResponse;
    FileSession: FileSession;
    onRefreshFunction: () => void;
}
export default function FileProperties({ FileDetailsObject, FolderItemClassResponse, FileSession, onRefreshFunction }: Props) {
    const [selectedDocumentClass, setSelectedDocumentClass] = useState<string | null>(null);
    const [dynamicProperties, setDynamicProperties] = useState<Record<string, string>>({});
    const [folderItemClassResponse, setFolderItemClassResponse] = useState<FolderItemClassResponse | undefined>(FolderItemClassResponse);
    const [fetchClasses, doFetchClasses] = useState(0);
    const [lookupData, setLookupData] = useState<Record<string, Array<{ id: string; name: string }>>>({});

    useEffect(() => {
        console.log("Dynamic Properties changed:", dynamicProperties);
    }, [dynamicProperties]);

    const fetchLookupDataForProperty = async (property: any) => {
        if (property.m_datatype === 'Lookup' && property.query) {
            if (lookupData[property.o_propname]) {
                return; // Skip fetch if data already exists
            }

            const lookupDataRequest = { query: property.query };
            try {
                const result = await fetchLookupData(lookupDataRequest, FileSession);
                if (result.data.Data) {
                    const transformedData = result.data.Data.map((item: any) => ({
                        id: item.id,
                        name: item.name
                    }));
                    setLookupData(prev => ({
                        ...prev,
                        [property.o_propname]: transformedData
                    }));
                }
            } catch (error) {
                console.error('Error fetching lookup data:', error);
            }
        }
    };

    const handleLookupFocus = async (property: any) => {
        await fetchLookupDataForProperty(property);
    };

    const mapIdsToTextValues = (properties: any[], lookupData: Record<string, Array<{ id: string; name: string }>>) => {
        const mappedProperties: Record<string, any> = {};
        properties.forEach((property: any) => {
            if (property.m_datatype === 'Lookup' && property.text) {
                mappedProperties[property.o_propname] = property.text;
            } else {
                mappedProperties[property.o_propname] = property.value;
            }
        });
        return mappedProperties;
    };

    useEffect(() => {
        async function fetchClassProperties() {
            try {
                const result = await fetchClassPropertiesService(FileSession);
                if (result.data) {
                    if (result.data.Success) {
                        const fileClasses = result.data.Data;

                        let itemClassResponse: FolderItemClassResponse = {
                            classes: fileClasses?.classes.map((classItem: any) => ({
                                model: classItem?.model,
                                name: classItem?.name,
                                obj_int_id: classItem?.obj_int_id,
                                o_classname: classItem?.o_classname,
                                m_objtype_alias: classItem?.m_objtype_alias,
                                m_class_alias: classItem?.m_class_alias,
                                creation_date: classItem?.creation_date,
                                objects: classItem?.objects.map((objectItem: any) => ({
                                    o_objname: objectItem?.o_objname,
                                    o_ID: objectItem?.o_ID,
                                    m_ID: objectItem?.m_ID,
                                    o_docid: objectItem?.o_docid,
                                    o_attachments: objectItem?.o_attachments,
                                    created_by: objectItem?.created_by,
                                    modified_by: objectItem?.modified_by,
                                    created_by_mfile_user: objectItem?.created_by_mfile_user,
                                    m_class_alias: objectItem?.m_class_alias,
                                    version: {
                                        created: objectItem?.version?.created,
                                        lastmodified: objectItem?.version?.lastmodified,
                                        properties: objectItem?.version?.properties.map((property: any) => ({
                                            o_propname: property?.o_propname,
                                            m_datatype: property?.m_datatype,
                                            m_alias: property?.m_alias,
                                            value: property?.value,
                                            text: property?.text,
                                            referece_class_alias: property?.referece_class_alias,
                                            relational_table: property?.relational_table,
                                            is_obligatory: property?.is_obligatory,
                                            is_editable: property?.is_editable,
                                            query: property?.query,
                                            value_list_data: property?.value_list_data,
                                        })),
                                    },
                                    find_rule: objectItem?.find_rule,
                                })),
                            })),
                        };

                        setFolderItemClassResponse(itemClassResponse);
                    } else {
                       // showToast("An error occurred. Please refresh and try again", "Attached Document", "e-toast-danger");
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        fetchClassProperties();
    }, [fetchClasses, FileSession]);

    useEffect(() => {
        async function fetchSelectedFileContent() {
            try {
                if (FileDetailsObject && FileDetailsObject.id) {
                    const result = await filePropertyService(FileDetailsObject.id, FileSession);
                    if (result.data) {
                        if (result.data.Success) {
                            const fileClasses = result.data.Data as unknown as FileDetailsObject;
                            const jsonPropertiesObj = JSON.parse(fileClasses.jsonProperties);
                            if (jsonPropertiesObj) {
                                const mainClassProperties = {
                                    model: jsonPropertiesObj.model,
                                    name: jsonPropertiesObj.name,
                                    obj_int_id: jsonPropertiesObj.obj_int_id,
                                    o_classname: jsonPropertiesObj.o_classname,
                                    m_objtype_alias: jsonPropertiesObj.m_objtype_alias,
                                    m_class_alias: jsonPropertiesObj.m_class_alias,
                                    creation_date: jsonPropertiesObj.creation_date
                                };

                                if (jsonPropertiesObj.objects) {
                                    const properties: Record<string, any> = {};
                                    for (const objectInfo of jsonPropertiesObj.objects) {
                                        const mappedProperties = mapIdsToTextValues(objectInfo.version.properties, lookupData);
                                        Object.assign(properties, mappedProperties);
                                    }
                                    setSelectedDocumentClass(mainClassProperties.name);
                                    setTimeout(() => {
                                        setDynamicProperties(properties);
                                    }, 500);
                                } else {
                                    setSelectedDocumentClass(null);
                                    setDynamicProperties({});
                                }
                            } else {
                                setSelectedDocumentClass(null);
                                setDynamicProperties({});
                            }
                        } else {
                            setSelectedDocumentClass(null);
                            setDynamicProperties({});
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        fetchSelectedFileContent();
    }, [FileDetailsObject, lookupData]);

    const handleDocumentClassChange = (selectedClass: string) => {
        setSelectedDocumentClass(selectedClass);
        setDynamicProperties({});
    };

    const handleInputChange = (propertyName: string, value: string) => {
        if (value) {
            setDynamicProperties((prevProperties) => ({
                ...prevProperties,
                [propertyName]: value,
            }));
        }
    };

    const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFileDetails = { ...FileDetailsObject, id: e.target.value };
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedFileDetails = { ...FileDetailsObject, name: e.target.value };
    };

    const handleDocumentClassFetch = () => {
        doFetchClasses(prev => prev + 1);
    };

    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 5000,
            cssClass: cssClass
        });
    };

    const handleSaveFunction = async () => {
        try {
            const existingClass = folderItemClassResponse?.classes.find((folderItemClass) => folderItemClass.name === selectedDocumentClass);

            if (!existingClass) {
                showToast("Selected class not found", "File Properties", "e-toast-warning");
                return;
            }

            const dynamicPropertiesList: FileClassProperty[] = existingClass.objects[0].version.properties.map((property) => {
                const value = dynamicProperties[property.o_propname];
                let text = value;

                if (property.m_datatype === 'Lookup' || property.m_datatype === 'ValueList') {
                    const selectedItem = lookupData[property.o_propname]?.find(item => item.id === value);
                    if (selectedItem) {
                        text = selectedItem.name;
                    }
                }

                return {
                    o_propname: property.o_propname,
                    m_datatype: property.m_datatype,
                    m_alias: property.m_alias,
                    value: value,
                    text: text,
                    referece_class_alias: property.referece_class_alias,
                    relational_table: property.relational_table,
                    is_obligatory: property.is_obligatory,
                    is_editable: property.is_editable,
                    query: property.query,
                    value_list_data: property.value_list_data,
                };
            });

            const fileClassRequest: FileClassRequest = {
                classes: [
                    {
                        model: FileDetailsObject.jsonProperties.model,
                        name: selectedDocumentClass || '',
                        obj_int_id: FileDetailsObject.jsonProperties.obj_int_id,
                        o_classname: FileDetailsObject.jsonProperties.o_classname,
                        m_objtype_alias: FileDetailsObject.jsonProperties.m_objtype_alias,
                        m_class_alias: FileDetailsObject.jsonProperties.m_class_alias,
                        creation_date: FileDetailsObject.jsonProperties.creation_date,
                        objects: [
                            {
                                o_objname: FileDetailsObject.name,
                                o_ID: FileDetailsObject.id,
                                m_ID: null,
                                o_docid: null,
                                o_attachments: null,
                                created_by: FileDetailsObject.jsonProperties.created_by,
                                modified_by: FileDetailsObject.jsonProperties.modified_by,
                                created_by_mfile_user: FileDetailsObject.jsonProperties.created_by_mfile_user,
                                m_class_alias: FileDetailsObject.jsonProperties.m_class_alias,
                                version: {
                                    created: new Date().toISOString(),
                                    lastmodified: new Date().toISOString(),
                                    properties: dynamicPropertiesList,
                                },
                                find_rule: FileDetailsObject.jsonProperties.find_rule
                            }
                        ]
                    }
                ]
            };

            const result = await saveClassPropertiesService(fileClassRequest, FileDetailsObject.id, FileSession);

            if (result.data && result.data.Success) {
                showToast("Save successful", "File Properties", "e-toast-success");
                onRefreshFunction();
            } else {
                //showToast("An error occurred", "File Properties", "e-toast-warning");
            }
        } catch (error) {
            console.error(error);
            //showToast("An error occurred", "File Properties", "e-toast-danger");
        }
    };

    const getLookupTextForId = (propertyName: string, id: string) => {
        const lookupItem = lookupData[propertyName]?.find(item => item.id === id);
        return lookupItem ? lookupItem.name : id;
    };

    const handleCancelFunction = () => {
        // Handle cancel logic if needed
    };

    const handleResetFunction = () => {
        // Handle reset logic if needed
    };

    const renderControls = () => {
        if (selectedDocumentClass && folderItemClassResponse && folderItemClassResponse.classes) {
            const properties = folderItemClassResponse.classes
                .find(folderItemClass => folderItemClass.name === selectedDocumentClass)
                ?.objects[0]?.version?.properties;

            if (!properties) {
                return null;
            }

            return properties.map((property) => {
                let inputElement: JSX.Element | null = null;

                switch (property.m_datatype) {
                    case 'Date':
                        inputElement = (
                            <DatePickerComponent
                                className="e-input dynamic-input"
                                style={{ backgroundColor: '#2e2e2e', color: 'white', borderColor: '#2e2e2e' }}
                                value={new Date(dynamicProperties[property.o_propname] || new Date())}
                                onChange={(date: any) => handleInputChange(property.o_propname, date.toString())}
                            />
                        );
                        break;
                    case 'MultiSelect':
                        inputElement = (
                            <MultiSelectComponent
                                className="e-input dynamic-input"
                                dataSource={property.value_list_data.map(item => ({ Name: item.name, Id: item.id }))}
                                mode="Box"
                                fields={{ text: 'Name', value: 'Id' }}
                                value={dynamicProperties[property.o_propname]?.split(',')}
                                change={(e) => handleInputChange(property.o_propname, e.value.join(','))}
                            />
                        );
                        break;
                    case 'Lookup':
                    case 'ValueList':
                        const selectedItem = lookupData[property.o_propname]?.find(item => item.id === dynamicProperties[property.o_propname]);
                        const selectedValue = selectedItem ? selectedItem.name : dynamicProperties[property.o_propname];
                        inputElement = (
                            <ComboBoxComponent
                                key={property.o_propname}
                                dataSource={lookupData[property.o_propname] || []}
                                onFocus={() => handleLookupFocus(property)}
                                fields={{ text: 'name', value: 'id' }}
                                allowFiltering={true}
                                autofill={true}
                                placeholder='Select an option'
                                value={selectedValue}
                                change={(e) => handleInputChange(property.o_propname, e.itemData?.id)}
                            />
                        );
                        break;
                    default:
                        inputElement = (
                            <input
                                type="text"
                                className="e-input dynamic-input"
                                value={dynamicProperties[property.o_propname] || ''}
                                onChange={(e) => handleInputChange(property.o_propname, e.target.value)}
                            />
                        );
                        break;
                }

                return (
                    <div key={property.o_propname} className="properties-row">
                        <div className="properties-label">{property.o_propname}</div>
                        <div className="properties-value">{inputElement}</div>
                    </div>
                );
            });
        }
        return null;
    };

    return (
        <div className="properties-container">
            <div className="properties-sub-container">
                <div className="properties-row">
                    <div className="properties-label">Id</div>
                    <div className="properties-value">
                        <input
                            type="text"
                            className="e-input dynamic-input"
                            value={FileDetailsObject.id}
                            onChange={handleIdChange}
                        />
                    </div>
                </div>

                <div className="properties-row">
                    <div className="properties-label">File Name</div>
                    <div className="properties-value">
                        <input
                            type="text"
                            className="e-input dynamic-input"
                            value={FileDetailsObject.name}
                            onChange={handleNameChange}
                        />
                    </div>
                </div>

                <div className="properties-row">
                    <div className="properties-label">Document Class</div>
                    <div className="properties-value">
                        {folderItemClassResponse && folderItemClassResponse.classes && folderItemClassResponse.classes.length > 0 ? (
                            <ComboBoxComponent
                                className="dynamic-input"
                                dataSource={folderItemClassResponse.classes.map((folderItemClass) => folderItemClass.name)}
                                onClick={() => handleDocumentClassFetch()}
                                placeholder="Select Document Class"
                                autofill={true}
                                value={selectedDocumentClass || ''}
                                change={(e) => handleDocumentClassChange(e.value)}
                            />
                        ) : null}
                    </div>
                </div>

                {renderControls()}
            </div>
            <div className="properties-row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <div className="e-toolbar-item">
                    <ButtonComponent
                        iconCss="e-icons e-save-changes"
                        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib properties-button"
                        onClick={handleSaveFunction}
                        title="Save"
                        iconPosition="Left"
                    >
                        Save
                    </ButtonComponent>
                </div>

                <div className="e-toolbar-item">
                    <ButtonComponent
                        iconCss="e-icons e-refresh"
                        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib properties-button"
                        iconPosition="Left"
                        title="Reset"
                        onClick={handleResetFunction}
                    >
                        Reset
                    </ButtonComponent>
                </div>

                <div className="e-toolbar-item">
                    <ButtonComponent
                        iconCss="e-icons e-cancel-action"
                        className="e-tbar-btn e-tbtn-txt e-control e-btn e-lib properties-button"
                        iconPosition="Left"
                        title="Cancel"
                        onClick={handleCancelFunction}
                    >
                        Cancel
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
}