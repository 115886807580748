import { ToastUtility } from '@syncfusion/ej2-notifications';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fileOpenService, fileSaveService } from '../../apis/fileService';
import { FileSession } from '../../models/FileSession';
import { SaveFileRequest } from '../../models/SaveFileRequest';
import "../FileManager/FileManagerImageEditor.scss";
import NavBar from '../NavBar';
import FileManagerStyledDialog from './FileManagerStyledDialog';

interface Props {
    fileSession: FileSession;
}
export default function FileManagerImageEditor({ fileSession }: Props) {
    const imageRef = useRef<ImageEditorComponent>(null);
    const location = useLocation();
    const [imageSrc, setImageSrc] = useState<string>();
    const queryParams = new URLSearchParams(location.search);
    const filePath = queryParams.get('path') ?? "";
    const fileName = queryParams.get('name') ?? "";
    const fileProvider = queryParams.get('provider') ?? "";
    const [temporaryName, setTemporaryName] = useState<string>("");
    const [fileRenameDialogVisibility, setFileRenameDialogVisibility] = useState<boolean>(false);
    const [selectedFileType, setSelectedFileType] = React.useState<string>("");
    const saveAsData: string[] = ['PNG', 'JPEG', 'JPG', 'GIF', 'ICO', 'TIFF'];

    // create a state variable to store the data source
    const [dataSource, setDataSource] = React.useState<string[]>([]);

   

    // update the data source after the component is mounted
    useEffect(() => {
        setDataSource(saveAsData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (selectedFileType) {
            let currentName = temporaryName ?? fileName;
            const dotPosition = currentName.lastIndexOf('.');
            const tempName = fileName.slice(0, dotPosition) + "." + selectedFileType.toLocaleLowerCase();
            setTemporaryName(tempName);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFileType]);


    useEffect(() => {
        if (imageRef && imageRef.current && imageSrc) {
            imageRef.current.open("data:image/png;base64," + imageSrc);
            console.log("image opened");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageRef, imageSrc]);


    useEffect(() => {
        async function fetchSelectedFileContent() {
            const result = await fileOpenService(filePath, fileName, fileProvider ?? "", fileSession);
            debugger;
            var data = result?.data?.Data;
            if (data) {
                setImageSrc(data.FileBase64String);
            }
        }
        fetchSelectedFileContent().then(
            () => {

            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const showToast = (message: string, title: string, cssClass: string) => {
        ToastUtility.show({
            title: title,
            content: message,
            position: { X: 'Center', Y: 'Top' },
            timeOut: 4000,
            cssClass: cssClass,

        });
    }


    let isSaving = false;
    const saveChanges = (path: string, name: string) => {
        if (isSaving) return;
        isSaving = true;
        async function fetchSelectedFileContent() {
            try {

                if (imageRef && imageRef.current) {
                    var tempData = imageRef.current.getImageData();
                    showToast("Please wait...", "Saving Changes", "e-toast-success");
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.width = tempData.width;
                    canvas.height = tempData.height;
                    ctx!.putImageData(tempData, 0, 0);
                    var base64Data = canvas.toDataURL();

                    let saveFileRequest = new SaveFileRequest();
                    saveFileRequest.base64String = base64Data;
                    saveFileRequest.id = path;
                    saveFileRequest.name = name;
                    saveFileRequest.providerId = fileProvider;
                    const result = await fileSaveService(saveFileRequest, fileSession);
                    var data = result?.data?.Data;
                    if (data || result?.data?.Success) {
                        setImageSrc(data.FileBase64String);
                        showToast("Saved Successfully", "Saving Changes", "e-toast-success");
                    }
                    else {
                        showToast("An error occurred while saving the changes. Please try again!", "Saving Changes", "e-toast-danger");
                    }
                }

            } catch (e) {
                console.log(e);
            }
            finally {
                isSaving = false;
            }
        }
        fetchSelectedFileContent().then(
            () => {
                //selectCurrentFolder(path);
            }
        );
    }


    const handleTemporaryNameChange = (e: any) => {
        setTemporaryName(e.target.value);
    }


    //Rename Files Function
    const handleFileRename = () => {
        let fileRegex = /^[^<>:"/\\|?*]+\.[^<>:"/\\|?*]+$/;
        if (!fileRegex.test(temporaryName.toLowerCase())) {
            showToast("Invalid filename.", "Save As", "e-toast-warning");
            return;
        }

        var fileExt = fileName?.split('.').pop();
        var newFileExt = temporaryName?.split('.').pop()?.toLocaleLowerCase();

        if (!newFileExt && !fileExt) {
            showToast("Invalid filename.", "Save As", "e-toast-warning");
            return;
        }

        if (!selectedFileType) {
            showToast("Please select a file type", "Save As", "e-toast-warning");
            return;
        }

        if (newFileExt === "png" ||
            newFileExt === "jpg" ||
            newFileExt === "jpeg" ||
            newFileExt === "tiff" ||
            newFileExt === "ico" ||
            newFileExt === "gif") {

            const dirPath = getDirName(filePath);
            let currentPath = (dirPath + "/" + (temporaryName ?? fileName));
            debugger;
            if (newFileExt === fileExt) {

                if (currentPath) {
                    saveChanges(currentPath, temporaryName);
                }
            }
            else {
                //no change
                if (newFileExt === temporaryName) {
                    if (currentPath) {
                        saveChanges(currentPath, `${temporaryName}.${fileExt}`);
                    }
                }
                else {
                    if (currentPath) {
                        saveChanges(currentPath, temporaryName);
                    }
                }
            }

            setFileRenameDialogVisibility(false);
        }
        else {
            showToast("Unsupported File Extension :" + newFileExt, "Save As", "e-toast-danger");
            return;
        }
    }


    // handle the change event
    const handleChange = (args: any) => {
        // get the selected item's text and value
        const selectedItemText = args.itemData.text;
        const selectedItemValue = args.itemData.value;

        // update the state variable with the selected value
        setSelectedFileType(selectedItemValue);

        // display the selected item's text and value in the console
        console.log('Selected item text: ' + selectedItemText);
        console.log('Selected item value: ' + selectedItemValue);
    };


    function getDirName(filePath: string) {
        // find the last index of '/' or '\\' in the file path
        let lastIndex = Math.max(filePath.lastIndexOf('/'), filePath.lastIndexOf('\\'));
        // if no separator is found, return an empty string
        if (lastIndex === -1) {
            return '/';
        }
        // if the separator is the first and only character, return it
        if (lastIndex === 0) {
            return filePath[0];
        }
        // otherwise, return the substring before the separator
        return filePath.slice(0, lastIndex);
    }

    return (
        <div style={{ height: '100vh', 'width': '100%' }} >
            <NavBar
                filePath={filePath}
                fileName={fileName}
                showSaveAs={false}
                saveFunction={() => { saveChanges(filePath, fileName); }
                }
                saveAsFunction={() => { setTemporaryName(fileName); setFileRenameDialogVisibility(true); }}
                cancelFunction={() => { window.close() }} closeFunction={() => { window.close() }}
            />

            <div className="e-card"
                style={{
                    backgroundColor: '#f3f2f1',
                    height: '95vh'
                }} id="image-editor">

                <ImageEditorComponent ref={imageRef}  ></ImageEditorComponent>
            </div>

            <FileManagerStyledDialog
                visible={fileRenameDialogVisibility}
                size={"sx"}
                target='#image-editor'
                isSaving={false}
                canResize={false}
                allowDragging={false}
                title={"Save As"}
                showSaveButton={false}

                setOpen={function (value: boolean): void {
                    setFileRenameDialogVisibility(value);
                }}

                saveChanges={async function (value: boolean): Promise<void> {

                }}

                buttons={[
                    {
                        buttonModel: {
                            content: 'Cancel',
                            iconCss: 'e-icons e-close-dialog',
                            isPrimary: false,
                            cssClass: 'e-flat toolbar-button'
                        },

                        click: () => setFileRenameDialogVisibility(false)
                    },
                    {
                        buttonModel: {
                            content: 'Save',
                            iconCss: 'e-icons e-ok-check-mark',
                            cssClass: 'e-flat toolbar-button',
                            isPrimary: true
                        },

                        click: () => handleFileRename()
                    }
                ]}

            >
                <>
                    <div style={{ padding: '5px', marginRight: '10px' }} >
                        <input
                            className="e-input"
                            type="text"
                            value={temporaryName}
                            placeholder="New File Name"
                            onChange={handleTemporaryNameChange}
                        />
                        <div style={{ height: '10px' }}></div>

                        <DropDownListComponent id="ddlelement"
                            dataSource={dataSource}
                            change={handleChange} />
                    </div>
                </>

            </FileManagerStyledDialog>
        </div>

    )
}
