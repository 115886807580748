/**
 * Represents a FileSession object with session information.
 *
 * @class FileSession
 */
export class FileSession {
    /**
     * The session ID.
     * @type {string | undefined}
     */
    sessionId;

    /**
     * The session host.
     * @type {string | undefined}
     */
    sessionHost;

    /**
     * The session UID.
     * @type {string | undefined}
     */
    sessionUid;

    /**
     * The B2C session information.
     * @type {string | undefined}
     */
    sessionB2C;

    /**
     * Creates a new FileSession object.
     *
     * @param {string | undefined} [sessionId] - The session ID.
     * @param {string | undefined} [sessionHost] - The session host.
     * @param {string | undefined} [sessionUid] - The session UID.
     * @param {string | undefined} [sessionB2C] - The B2C session information.
     */
    constructor(
        sessionId?: string,
        sessionHost?: string,
        sessionUid?: string,
        sessionB2C?: string
    ) {
        this.sessionId = sessionId;
        this.sessionHost = sessionHost;
        this.sessionUid = sessionUid;
        this.sessionB2C = sessionB2C;
    }
}
