import { FileDetailsObject } from "../../models/FileDetails";

export enum ActionTypes {
    SELECT_FILE = "SELECT_FILE",
}

export type Action = SelectFileAction;

export interface SelectFileAction {
    type: ActionTypes.SELECT_FILE;
    payload: FileDetailsObject;
}

export const selectFile = (fileDetails: FileDetailsObject): SelectFileAction => ({
    type: ActionTypes.SELECT_FILE,
    payload: fileDetails,
});